/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Link } from "react-router-dom";
import ImageWithPlaceholder from "./ImageWithPlaceholder";

const dateStyle = css`
  font-style: italic;
  color: grey;
`;

const container = css`
  padding: 10px;
  border: solid rgb(218, 218, 218) 1px;
  border-radius: 3px;
`;

const item = css`
  width: 100%;
  height: 100%;
  img {
    width: 400px;
    max-width: 100%;
    height: 400px;
    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
  }
`;

const title = css`
  color: black;
  margin-top: 7px;
  font-size: 15pt;
`;

const link = css`
  text-decoration: none;
  font-family: "handwritten";
`;

const Card = (props) => {
  let date;
  if (props.date) {
    date = (
      <div css={dateStyle}>
        {new Date(props.date).toLocaleDateString("en-US")}
      </div>
    );
  }
  return (
    <Link to={props.link} key={props.itemId} css={link}>
      <div css={container}>
        <div css={item}>
          <ImageWithPlaceholder imageUrl={props.imageUrl} />
        </div>
        <div css={title}>{props.itemTitle}</div>
        {date}
      </div>
    </Link>
  );
};
export default Card;
