/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import background from "../../assets/square-placeholder.png";
import { useState } from "react";
import { Link } from "react-router-dom";

const linkStyle = css`
  color: inherit;
  text-decoration: none;
`;

const product = css`
  width: 100%;
  margin-bottom: 25px;
`;

const imageStyleLoaded = css`
  position: absolute;
  opacity: 1;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
`;

const imageStyleNotLoaded = css`
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0;
  width: 0;
  height: 0;
  object-fit: cover;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
`;

const imageWrapper = css`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-color: #2980b9;
  background: url(${background});
  background-position: center center;
  background-size: cover;
`;

const info = css`
  font-family: "handwritten";
  font-size: 13pt;
  text-align: center;
  padding: 10px 10px 0 10px;
  font-weight: bold;
`;

const line = css`
  width: 25px;
`;

const price = css`
  margin-top: 15px;
  font-family: "arial";
  text-align: center;
`;

const priceSold = css`
  color: #eb6238;
`;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Product = ({ data, link }) => {
  const [imageHasLoaded, setImageHasLoaded] = useState(false);

  const handleOnImageLoad = () => {
    setImageHasLoaded(true);
  };

  return (
    <Link to={link} css={linkStyle}>
      <div css={product}>
        <div css={imageWrapper}>
          <img
            src={data.imageSrc}
            css={imageHasLoaded ? imageStyleLoaded : imageStyleNotLoaded}
            alt={data.imageSrc}
            onLoad={handleOnImageLoad}
          />
        </div>
        <div css={{ minHeight: 110 }}>
          <div css={info}>
            {data.title} - {data.info} - {data.dimensions}
          </div>
          <hr css={line} />
          <div css={[price, data.quantity === 0 ? priceSold : null]}>
            {data.quantity === 0
              ? "Sold"
              : `$${numberWithCommas(data.price)}.00`}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Product;
