/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Box } from "@material-ui/core";
import facebookLogo from "../assets/facebook.png";
import instagramLogo from "../assets/instagram.png";

const footerContainer = css`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 20px;
`;

const footerInside = css`
  display: flex;
  align-items: center;
`;

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div css={footerContainer}>
      <div css={footerInside}>
        <div>&copy; {year}</div>
        <Box width={50} />
        <a target="blank" href="http://www.facebook.com/zatchinabox">
          <img src={facebookLogo} alt={"facebook"} width={30} height={30} />
        </a>
        <Box width={10} />
        <a target="blank" href="http://www.instagram.com/zatchinabox">
          <img src={instagramLogo} alt={"instagram"} width={30} height={30} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
