/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder";
import { useViewport } from "../../customHooks";
import { Link } from "react-router-dom";

const GRID_SPACING = 14;

const workStyle = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding-left: 18px;
  padding-right: 18px;
  max-width: 100%;
  box-sizing: border-box;
`;

const gridContainer = css`
  display: flex;
  justify-content: space-between;
`;

const column = css`
  width: 324px;
`;

const Column = ({ children, isMobile }) => {
  return (
    <div css={[column, { width: isMobile ? "100%" : 324 }]}>{children}</div>
  );
};

const NB_ITEMS_TO_LOAD = 12;

const Work = ({ onImageClick }) => {
  const [isBottom, setIsBottom] = useState(true);
  const [offset, setOffset] = useState(0);
  const { width } = useViewport();
  const desktopBreakpoint = 1020;
  const mobileBreakpoint = 700;
  const [deviceType, setDeviceType] = useState(
    width <= mobileBreakpoint
      ? "mobile"
      : width <= desktopBreakpoint
      ? "tablet"
      : "desktop"
  );
  const [columns, setColumns] = useState(
    deviceType === "desktop"
      ? [
          { width: 324, height: 0, data: [] },
          { width: 324, height: 0, data: [] },
          { width: 324, height: 0, data: [] },
        ]
      : deviceType === "tablet"
      ? [
          { width: 324, height: 0, data: [] },
          { width: 324, height: 0, data: [] },
        ]
      : [{ width: 324, height: 0, data: [] }]
  );
  const [containerWidth, setContainerWidth] = useState(
    columns.length * 324 + (columns.length - 1) * GRID_SPACING
  );

  useEffect(() => {
    setDeviceType(
      width <= mobileBreakpoint
        ? "mobile"
        : width <= desktopBreakpoint
        ? "tablet"
        : "desktop"
    );
  }, [width]);

  useEffect(() => {
    setOffset(0);
    setColumns(
      deviceType === "desktop"
        ? [
            { width: 324, height: 0, data: [] },
            { width: 324, height: 0, data: [] },
            { width: 324, height: 0, data: [] },
          ]
        : deviceType === "tablet"
        ? [
            { width: 324, height: 0, data: [] },
            { width: 324, height: 0, data: [] },
          ]
        : [{ width: 324, height: 0, data: [] }]
    );
    setIsBottom(true);
  }, [deviceType]);

  useEffect(() => {
    setContainerWidth(
      columns.length === 1
        ? "100%"
        : columns.length * 324 + (columns.length - 1) * GRID_SPACING
    );
  }, [columns.length]);

  useEffect(() => {
    document.title = "Work | Zatchinabox";
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
      document.documentElement.scrollHeight - 400
    ) {
      setIsBottom(true);
    }
  };

  const fetchWorkImages = () => {
    axios
      .get("/api/artwork/" + offset + "/" + NB_ITEMS_TO_LOAD)
      .then((results) => {
        let newColumns = JSON.parse(JSON.stringify(columns));
        for (let item of results.data) {
          let ratio = 324 / item.imageWidth;
          let newHeight = item.imageHeight * ratio;
          let newWidth = 324;
          let indexOfMinHeightColumn = 0;
          let min = Number.POSITIVE_INFINITY;
          for (let i = 0; i < newColumns.length; i++) {
            if (min > newColumns[i].height) {
              min = newColumns[i].height;
              indexOfMinHeightColumn = i;
            }
          }
          newColumns[indexOfMinHeightColumn].data.push({
            id: item.id,
            title: item.title,
            imageSrc: item.imageSrc,
            imageHeight: newHeight,
            imageWidth: newWidth,
            soldable: item.soldable,
          });
          newColumns[indexOfMinHeightColumn].height += newHeight;
        }
        setColumns(newColumns);
        setOffset(offset + 1);
        setIsBottom(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isBottom) {
      fetchWorkImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOnImageClick = (item) => {
    if (onImageClick) {
      onImageClick({
        url: item.url,
        title: item.title,
      });
    }
  };

  return (
    <div css={workStyle}>
      <Box height={180} />
      <div css={[gridContainer, { width: containerWidth }]}>
        {columns.map((col, index) => {
          return (
            <Column key={index} isMobile={deviceType === "mobile"}>
              {col.data.map((item, index) => {
                if (!item.soldable) {
                  return (
                    <ImageWithPlaceholder
                      style={{
                        height:
                          deviceType === "mobile" ? "auto" : item.imageHeight,
                        marginBottom: 14,
                      }}
                      title={item.title}
                      imageUrl={item.imageSrc}
                      onClick={handleOnImageClick}
                      key={item.imageSrc}
                    />
                  );
                } else {
                  return (
                    <Link
                      to={"/shop/" + item.id}
                      css={{ textTransform: "none" }}
                      key={item.imageSrc}
                    >
                      <ImageWithPlaceholder
                        style={{
                          height:
                            deviceType === "mobile" ? "auto" : item.imageHeight,
                          marginBottom: 14,
                        }}
                        imageUrl={item.imageSrc}
                      />
                    </Link>
                  );
                }
              })}
            </Column>
          );
        })}
      </div>
    </div>
  );
};

export default Work;
