/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link, NavLink } from "react-router-dom";
import { jsx, css } from "@emotion/react";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import { useViewport } from "../customHooks";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../assets/logo.png";

const title = css`
  color: #adadad;
  font-family: "handwritten";
  font-size: 20pt;
  text-decoration: none;
  padding-left: 15px;
  padding-right: 15px;
`;

const appbar = css`
  box-shadow: none !important;
  background-color: white;
`;

const toolbar = css`
  background-color: white;
`;

const rightMenu = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const NavBar = (props) => {
  const { width } = useViewport();
  const breakpoint = 830;

  const isDesktop = (width) => {
    return width >= breakpoint;
  };

  return (
    <div>
      {isDesktop(width) ? (
        <HideOnScroll {...props}>
          <AppBar css={appbar}>
            <Toolbar css={toolbar}>
              <Link to="/">
                <img
                  src={logo}
                  width="100px"
                  height="100px"
                  style={{ display: "block" }}
                  alt="logo"
                />
              </Link>
              <div css={rightMenu}>
                <NavLink
                  to="/work"
                  css={title}
                  activeStyle={{ color: "black", textDecoration: "underline" }}
                >
                  Work
                </NavLink>
                <NavLink
                  to="/exhibitions"
                  css={title}
                  activeStyle={{ color: "black", textDecoration: "underline" }}
                >
                  Exhibitions
                </NavLink>
                {/*<NavLink
                  to="/news"
                  css={title}
                  activeStyle={{ color: "black", textDecoration: "underline" }}
                >
                  News
                </NavLink>*/}
                <NavLink
                  to="/nft"
                  css={title}
                  activeStyle={{ color: "black", textDecoration: "underline" }}
                >
                  NFT
                </NavLink>
                <NavLink
                  to="/shop"
                  css={title}
                  activeStyle={{ color: "black", textDecoration: "underline" }}
                >
                  Shop
                </NavLink>
                <NavLink
                  to="/about"
                  css={title}
                  activeStyle={{ color: "black", textDecoration: "underline" }}
                >
                  About
                </NavLink>
                <NavLink
                  to="/contact"
                  css={title}
                  activeStyle={{ color: "black", textDecoration: "underline" }}
                >
                  Contact
                </NavLink>
              </div>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      ) : (
        <HideOnScroll>
          <AppBar css={appbar}>
            <Toolbar css={toolbar} style={{ justifyContent: "space-between" }}>
              <IconButton
                edge="start"
                color="primary"
                aria-label="menu"
                onClick={props.toggleDrawer(true)}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <Link to="/">
                <img
                  src={logo}
                  width={50}
                  style={{ display: "block" }}
                  alt="logo"
                />
              </Link>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      )}
    </div>
  );
};

export default NavBar;
