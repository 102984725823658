import Home from "./scenes/home";
import Contact from "./scenes/contact";
import Work from "./scenes/work";
import About from "./scenes/about";
import News from "./scenes/news";
import NFTs from "./scenes/nfts";
import Exhibitions from "./scenes/exhibitions";
import Exhibition from "./scenes/exhibition";
import Shop from "./scenes/shop";
import Product from "./scenes/product";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/work",
    component: Work,
    onImageClick: true,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/nft",
    component: NFTs,
  },
  /*{
    path: "/news",
    component: News,
  },*/
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/exhibitions",
    component: Exhibitions,
  },
  {
    path: "/exhibitions/:exhibitionId",
    component: Exhibition,
    onImageClick: true,
  },
  {
    path: "/shop",
    component: Shop,
  },
  {
    path: "/shop/:productId",
    component: Product,
    onImageClick: true,
  },
];

export default routes;
