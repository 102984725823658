/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { useState, useRef, useEffect } from "react";
import axios from "axios";

const container = css`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
`;

const inside = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const formContainer = css`
  padding: 15;
  @media (max-width: 720px) {
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
    box-sizing: border-box;
  }
`;

const input = css`
  width: 100%;
`;

const description = css`
  font-family: "handwritten";
  font-size: 20pt;
  max-width: 700px;
  text-align: justify;
  @media (max-width: 720px) {
    font-size: 16pt;
  }
`;

const title = css`
  font-family: "handwritten";
  font-size: 25pt;
  @media (max-width: 720px) {
    font-size: 20pt;
  }
`;

const buttonContainer = css`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 720px) {
    button {
      flex: 1;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
`;

const Contact = () => {
  const formRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = () => {
    setSubmitted(true);
    axios
      .post("/api/email", {
        name: name,
        email: email,
        message: message,
      })
      .then((res) => {
        setName("");
        setEmail("");
        setMessage("");
        alert("Message sent!");
        setSubmitted(false);
      })
      .catch((err) => {
        alert(err);
        setSubmitted(false);
      });
  };

  const handleEmailChange = (ev) => {
    setEmail(ev.target.value);
  };

  const handleNameChange = (ev) => {
    setName(ev.target.value);
  };

  const handleMessageChange = (ev) => {
    setMessage(ev.target.value);
  };

  useEffect(() => {
    document.title = "Contact | Zatchinabox";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={container}>
      <div css={inside}>
        <Box height={180} />
        <ValidatorForm
          ref={formRef}
          onSubmit={handleSubmit}
          css={formContainer}
        >
          <div css={description}>
            It's always interesting to receive feedback or artistic ideas from
            anyone, so don't hesitate ! For any inquiries, commissions,
            questions about the artwork, feel free to send a message.
          </div>
          <Box height={30} />
          <div css={title}>Contact Me</div>
          <Box height={30} />
          <TextValidator
            css={input}
            label="Email"
            required
            onChange={handleEmailChange}
            name="email"
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            variant="outlined"
            inputProps={{ style: { fontFamily: "handwritten" } }}
            InputLabelProps={{ style: { fontFamily: "handwritten" } }}
          />
          <br />
          <TextValidator
            css={input}
            required
            label="Name"
            onChange={handleNameChange}
            name="name"
            value={name}
            validators={["required"]}
            errorMessages={["this field is required"]}
            variant="outlined"
            inputProps={{ style: { fontFamily: "handwritten" } }}
            InputLabelProps={{ style: { fontFamily: "handwritten" } }}
          />
          <br />
          <TextValidator
            css={input}
            required
            label="Message"
            onChange={handleMessageChange}
            name="message"
            value={message}
            multiline
            rows={8}
            validators={["required"]}
            errorMessages={["this field is required"]}
            variant="outlined"
            inputProps={{ style: { fontFamily: "handwritten" } }}
            InputLabelProps={{ style: { fontFamily: "handwritten" } }}
          />
          <br />
          <div css={buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={submitted}
            >
              Send
            </Button>
          </div>
        </ValidatorForm>
      </div>
    </div>
  );
};

export default Contact;
