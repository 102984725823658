/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { useEffect, useState } from "react";
import Card from "../../components/Card";

const container = css`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
`;

const inside = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const Exhibitions = () => {
  const [exhibitions, setExhibitions] = useState([]);

  useEffect(() => {
    document.title = "Exhibitions | Zatchinabox";
    window.scrollTo(0, 0);
    axios
      .get("/api/exhibitions")
      .then((results) => {
        setExhibitions(results.data);
      })
      .catch((err) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={container}>
      <div css={inside}>
        <Box height={180} />
        {exhibitions.map((item) => (
          <div key={item.id}>
            <Card
              link={`/exhibitions/${item.id}`}
              imageUrl={item.imageCoverSrc}
              itemTitle={item.title}
              date={item.date}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Exhibitions;
