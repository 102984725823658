/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import { useState, useRef, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  SelectValidator,
  TextValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import OrderSummary, { getTax, numberWithCommas } from "./OrderSummary";
import { countries } from "../../countries";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";

const cardname = css`
  background-color: white;
  box-sizing: border-box;
  padding: 18.5px 14px 18.5px 14px;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(207, 207, 207);
`;

const payButton = css`
  width: 100%;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
`;

const ckeckoutContainer = css`
  width: 600px;
  max-width: 100%;
  @media (max-width: 830px) {
    width: 100%;
  }
`;

const input = css`
  width: 100%;
`;

const line = css`
  height: 1px;
  background-color: #ccc;
  border: none;
`;

const cardError = css`
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
`;

const thankYou = css`
  font-size: 18pt;
  text-align: justify;
  margin-bottom: 30px;
`;

const orderNumberStyle = css`
  font-weight: 800;
`;

const confirmationText = css`
  text-align: justify;
`;

const applyButton = css`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background-color: #7bc86c;
  border-radius: 4px;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  text-transform: uppercase;
  color: white;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: #e0e0e0;
    color: #b3b3b3;
    &:hover {
      cursor: default;
    }
  }
`;

const promoCodeDefaultStyle = css`
  display: none;
`;

const promoCodeSuccessStyle = css`
  margin-top: 15px;
  display: flex;
  color: #33d64e;
`;

const promoCodeFailureStyle = css`
  margin-top: 15px;
  display: flex;
  color: #eb6464;
`;

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const createOrder = (productId, amount, clientInfoAsJson) =>
  new Promise((resolve, reject) => {
    axios
      .post("/api/orders", {
        artworkId: productId,
        amount: amount,
        clientInfoAsJson: JSON.stringify(clientInfoAsJson),
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

const applyPromoCode = (
  promoCode,
  paymentIntentId,
  item,
  destinationCountryCode
) =>
  new Promise((resolve, reject) => {
    axios
      .post("/api/payments/apply-promo-code", {
        _promoCode: promoCode,
        paymentIntentId: paymentIntentId,
        item: item,
        destinationCountryCode: destinationCountryCode,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

const StripeCheckoutForm = ({ product }) => {
  const stripe = useStripe();
  const elements = useElements();
  const formRef = useRef(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [cardDisabled, setCardDisabled] = useState(true);
  const [shippingRate, setShippingRate] = useState(null);
  const [shippingRateIsLoading, setShippingRateIsLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [orderNumber, setOrderNumber] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeSuccess, setPromoCodeSuccess] = useState(null);
  const [promoCodeFailure, setPromoCodeFailure] = useState(null);

  useEffect(() => {
    if (product) {
      setTotalAmount(product.price + getTax(product.price));
    }
  }, [product]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (product) {
      fetch("/api/payments", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ item: product }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setClientSecret(data.client_secret);
          setPaymentIntentId(data.payment_intent_id);
        })
        .catch((err) => console.log(err));
    }
  }, [product]);

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      // success
      createOrder(product.id, payload.paymentIntent.amount / 100, {
        clientFirstName: firstName,
        clientLastName: lastName,
        clientEmail: email,
        clientAddressLine1: addressLine1,
        clientAddressLine2: addressLine2,
        clientCity: city,
        clientZipCode: zip,
        clientState: state,
        clientCountryCode: country,
      })
        .then((res) => {
          setOrderNumber(res.data.orderNumber);
          setError(null);
          setProcessing(false);
          setSucceeded(true);
        })
        .catch((err) => {
          setProcessing(false);
          console.log(err);
        });
    }
  };

  const handleCardChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setCardDisabled(event.error || event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleFirstNameChange = (ev) => {
    setFirstName(ev.target.value);
  };

  const handleLastNameChange = (ev) => {
    setLastName(ev.target.value);
  };

  const handleEmailChange = (ev) => {
    setEmail(ev.target.value);
  };

  const handleAddressLine1Change = (ev) => {
    setAddressLine1(ev.target.value);
  };

  const handleAddressLine2Change = (ev) => {
    setAddressLine2(ev.target.value);
  };

  const handleCityChange = (ev) => {
    setCity(ev.target.value);
  };

  const handleStateChange = (ev) => {
    setState(ev.target.value);
  };

  const handleZipChange = (ev) => {
    setZip(ev.target.value);
  };

  const handleCountryChange = (ev) => {
    setShippingRateIsLoading(true);
    setCountry(ev.target.value);
    axios
      .put("/api/payments", {
        item: product,
        destinationCountryCode: ev.target.value,
        paymentIntentId: paymentIntentId,
        promoCodeApplied: promoCodeSuccess,
      })
      .then((res) => {
        setShippingRate(res.data.shipping_rate);
        setTotalAmount(res.data.total_amount);
        setShippingRateIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setShippingRateIsLoading(false);
      });
  };

  const handlePromoCodeChange = (ev) => {
    setPromoCode(ev.target.value);
  };

  const formIsValid = () => {
    if (
      firstName.trim().length > 0 &&
      lastName.trim().length > 0 &&
      email.trim().length > 0 &&
      validateEmail(email) &&
      addressLine1.trim().length > 0 &&
      city.trim().length > 0 &&
      zip.trim().length > 0 &&
      country.trim().length > 0
    )
      return true;
  };

  const handleFillTestForm = () => {
    handleFirstNameChange({
      target: {
        value: "Zachary",
      },
    });
    handleLastNameChange({
      target: {
        value: "Lithgow",
      },
    });
    handleEmailChange({
      target: {
        value: "zlithgow@gmail.com",
      },
    });
    handleAddressLine1Change({
      target: {
        value: "16 rue Jacquemont",
      },
    });
    handleCityChange({
      target: {
        value: "Paris",
      },
    });
    handleZipChange({
      target: {
        value: "75017",
      },
    });
    handleCountryChange({
      target: {
        value: "FR",
      },
    });
  };

  const handleFillBad = () => {
    handleFillTestForm();
  };

  const handleFillGood = () => {
    handleFillTestForm();
  };

  const handleFillAuth = () => {
    handleFillTestForm();
  };

  const handleApplyPromoCode = () => {
    applyPromoCode(promoCode, paymentIntentId, product, country)
      .then((res) => {
        setPromoCodeSuccess(res.data.success);
        setPromoCodeFailure(!res.data.success);
        setTotalAmount(res.data.total_amount);
        setShippingRate(res.data.shipping_rate);
      })
      .catch((err) => {
        console.log(err);
        setPromoCodeFailure(true);
        setPromoCodeSuccess(false);
      });
  };

  return (
    <div css={ckeckoutContainer}>
      {product && (
        <div>
          <hr css={line} />
          <Box height={35} />
          <OrderSummary
            product={product}
            shippingRate={shippingRate}
            promoCodeSuccess={promoCodeSuccess}
            totalAmount={totalAmount}
          />
          <Box height={35} />
          <hr css={line} />
          <Box height={35} />
          {!(succeeded || orderNumber) && (
            <ValidatorForm ref={formRef} onSubmit={handleSubmit}>
              <div>Contact Info</div>
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                required
                label="First Name"
                onChange={handleFirstNameChange}
                name="firstName"
                value={firstName}
                validators={["required"]}
                errorMessages={["this field is required"]}
                variant="outlined"
              />
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                required
                label="Last Name"
                onChange={handleLastNameChange}
                name="lastName"
                value={lastName}
                validators={["required"]}
                errorMessages={["this field is required"]}
                variant="outlined"
              />
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                required
                label="Email"
                onChange={handleEmailChange}
                name="email"
                value={email}
                validators={["required", "isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
                variant="outlined"
              />
              <Box height={35} />
              <div>Shipping Address</div>
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                required
                label="Address Line 1"
                onChange={handleAddressLine1Change}
                name="addressLine1"
                value={addressLine1}
                validators={["required"]}
                errorMessages={["this field is required"]}
                variant="outlined"
              />
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                label="Address Line 2"
                onChange={handleAddressLine2Change}
                name="addressLine2"
                value={addressLine2}
                variant="outlined"
              />
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                required
                label="City"
                onChange={handleCityChange}
                name="city"
                value={city}
                validators={["required"]}
                errorMessages={["this field is required"]}
                variant="outlined"
              />
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                label="State/Province/Region"
                onChange={handleStateChange}
                name="state"
                value={state}
                variant="outlined"
              />
              <Box height={15} />
              <TextValidator
                disabled={processing}
                css={input}
                required
                label="Zip / Postal Code"
                onChange={handleZipChange}
                name="zip"
                value={zip}
                validators={["required"]}
                errorMessages={["this field is required"]}
                variant="outlined"
              />
              <Box height={15} />
              <SelectValidator
                disabled={processing}
                onChange={handleCountryChange}
                css={input}
                required
                label="Country"
                variant="outlined"
                name="country"
                validators={["required"]}
                errorMessages={["this field is required"]}
                value={country}
              >
                {countries.map((country) => (
                  <MenuItem value={country.code} key={country.code}>
                    {country.name}
                  </MenuItem>
                ))}
              </SelectValidator>
              <Box height={35} />
              <div>Promo Code</div>
              <Box height={15} />
              <div css={{ display: "flex" }}>
                <TextField
                  css={input}
                  variant="outlined"
                  onChange={handlePromoCodeChange}
                  value={promoCode}
                />
                <Box width={20} />
                <button
                  css={applyButton}
                  type="button"
                  onClick={handleApplyPromoCode}
                  disabled={promoCode.trim().length === 0}
                >
                  Apply
                </button>
              </div>
              <div
                css={
                  promoCodeSuccess
                    ? promoCodeSuccessStyle
                    : promoCodeFailure
                    ? promoCodeFailureStyle
                    : promoCodeDefaultStyle
                }
              >
                <i>
                  {promoCodeSuccess
                    ? "This promo code has been applied."
                    : "This promo code isn't valid."}
                </i>
              </div>
              <Box height={35} />
              <div>Payment Info</div>
              <Box height={15} />
              <CardElement
                disabled={processing}
                css={cardname}
                options={{
                  style: {
                    base: {
                      backgroundColor: "white",
                    },
                  },
                }}
                onChange={handleCardChange}
                required
              />
              <Box height={45} />
              {/*
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginBottom: 35,
                  }}
                >
                  <div
                    css={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button type="button" onClick={handleFillGood}>
                      fill good
                    </button>
                    <CopyToClipboard text="4242 4242 4242 4242">
                      <div>4242 4242 4242 4242</div>
                    </CopyToClipboard>
                  </div>
                  <div
                    css={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button type="button" onClick={handleFillAuth}>
                      fill auth
                    </button>
                    <CopyToClipboard text="4000 0025 0000 3155">
                      <div>4000 0025 0000 3155</div>
                    </CopyToClipboard>
                  </div>
                  <div
                    css={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <button type="button" onClick={handleFillBad}>
                      fill bad
                    </button>
                    <CopyToClipboard text="4000 0000 0000 9995">
                      <div>4000 0000 0000 9995</div>
                    </CopyToClipboard>
                  </div>
                </div>
                */}
              <Button
                color="primary"
                variant="contained"
                type="submit"
                css={payButton}
                disabled={
                  !formIsValid() ||
                  product.quantity === 0 ||
                  processing ||
                  cardDisabled ||
                  succeeded ||
                  shippingRateIsLoading ||
                  !shippingRate
                }
              >
                {processing
                  ? "Processing..."
                  : `Pay $${numberWithCommas(totalAmount)}.00`}
              </Button>
              <Box height={25} />
              {/* Show any error that happens when processing the payment */}
              {error && (
                <div css={cardError} role="alert">
                  {error}
                </div>
              )}
              {/* Show any error that happens when processing the payment */}
              {processing && (
                <div css={cardError} role="alert">
                  The payment is processing. Don't leave the page.
                </div>
              )}
            </ValidatorForm>
          )}
          {(succeeded || orderNumber) && (
            <div>
              <div css={thankYou}>Thank you {firstName}!</div>
              <div css={{ marginBottom: "10px" }}>
                Your order number is{" "}
                <span css={orderNumberStyle}>#{orderNumber}</span>.
              </div>
              <div css={confirmationText}>
                Your order was sent and is currently awaiting payment. You will
                receive a confirmation email shortly.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StripeCheckoutForm;
