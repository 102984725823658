/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { useEffect, useState } from "react";
import { useViewport } from "../../customHooks";
import Product from "./Product";

const NB_ITEMS_TO_LOAD = 12;
const GRID_SPACING = 45;
const COLUMN_WIDTH = Math.floor((1000 - GRID_SPACING * 2) / 3);

const container = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding-left: 18px;
  padding-right: 18px;
  max-width: 100%;
  box-sizing: border-box;
`;

const gridContainer = css`
  display: flex;
  justify-content: space-between;
`;

const column = css`
  width: ${COLUMN_WIDTH}px;
`;

const Column = ({ children, isMobile }) => {
  return (
    <div css={[column, { width: isMobile ? "100%" : COLUMN_WIDTH }]}>
      {children}
    </div>
  );
};

const Shop = () => {
  const [isBottom, setIsBottom] = useState(true);
  const [offset, setOffset] = useState(0);
  const { width } = useViewport();
  const desktopBreakpoint = 1020;
  const mobileBreakpoint = 700;
  const [deviceType, setDeviceType] = useState(
    width <= mobileBreakpoint
      ? "mobile"
      : width <= desktopBreakpoint
      ? "tablet"
      : "desktop"
  );
  const [columns, setColumns] = useState(
    deviceType === "desktop"
      ? [
          { width: COLUMN_WIDTH, height: 0, data: [] },
          { width: COLUMN_WIDTH, height: 0, data: [] },
          { width: COLUMN_WIDTH, height: 0, data: [] },
        ]
      : deviceType === "tablet"
      ? [
          { width: COLUMN_WIDTH, height: 0, data: [] },
          { width: COLUMN_WIDTH, height: 0, data: [] },
        ]
      : [{ width: COLUMN_WIDTH, height: 0, data: [] }]
  );
  const [containerWidth, setContainerWidth] = useState(
    columns.length * COLUMN_WIDTH + (columns.length - 1) * GRID_SPACING
  );

  useEffect(() => {
    setDeviceType(
      width <= mobileBreakpoint
        ? "mobile"
        : width <= desktopBreakpoint
        ? "tablet"
        : "desktop"
    );
  }, [width]);

  useEffect(() => {
    setOffset(0);
    setColumns(
      deviceType === "desktop"
        ? [
            { width: COLUMN_WIDTH, height: 0, data: [] },
            { width: COLUMN_WIDTH, height: 0, data: [] },
            { width: COLUMN_WIDTH, height: 0, data: [] },
          ]
        : deviceType === "tablet"
        ? [
            { width: COLUMN_WIDTH, height: 0, data: [] },
            { width: COLUMN_WIDTH, height: 0, data: [] },
          ]
        : [{ width: COLUMN_WIDTH, height: 0, data: [] }]
    );
    setIsBottom(true);
  }, [deviceType]);

  useEffect(() => {
    setContainerWidth(
      columns.length === 1
        ? "100%"
        : columns.length * COLUMN_WIDTH + (columns.length - 1) * GRID_SPACING
    );
  }, [columns.length]);

  useEffect(() => {
    document.title = "Shop | Zatchinabox";
    window.scrollTo(0, 0);
  }, []);

  const fetchProductImages = () => {
    axios
      .get("/api/artwork/" + offset + "/" + NB_ITEMS_TO_LOAD, {
        params: {
          shop: true,
        },
      })
      .then((results) => {
        let newColumns = columns;
        results.data.forEach((item) => {
          let ratio = COLUMN_WIDTH / item.imageWidth;
          let newHeight = item.imageHeight * ratio;
          let newWidth = COLUMN_WIDTH;
          let indexOfMinHeightColumn = 0;
          let min = Number.POSITIVE_INFINITY;
          for (let i = 0; i < newColumns.length; i++) {
            if (min > newColumns[i].height) {
              min = newColumns[i].height;
              indexOfMinHeightColumn = i;
            }
          }
          newColumns[indexOfMinHeightColumn].data.push({
            id: item.id,
            imageSrc: item.imageSrc,
            imageHeight: newHeight,
            imageWidth: newWidth,
            title: item.title,
            price: item.price,
            info: item.info,
            dimensions: item.dimensions,
            quantity: item.quantity,
          });
          newColumns[indexOfMinHeightColumn].height += newHeight;
        });

        setColumns(newColumns);
        setOffset(offset + 1);
        setIsBottom(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isBottom) {
      fetchProductImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  const handleScroll = () => {
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
      document.documentElement.scrollHeight - 400
    ) {
      setIsBottom(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div css={container}>
      <Box height={180} />
      <div css={[gridContainer, { width: containerWidth }]}>
        {columns.map((col, index) => {
          return (
            <Column key={index} isMobile={deviceType === "mobile"}>
              {col.data.map((item, index) => {
                return (
                  <Product
                    data={item}
                    key={item.imageSrc}
                    link={`/shop/${item.id}`}
                  />
                );
              })}
            </Column>
          );
        })}
      </div>
    </div>
  );
};

export default Shop;
