/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Box from "@material-ui/core/Box";
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder";
import { useViewport } from "../../customHooks";

const GRID_SPACING = 14;

const exhibitionStyle = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding-left: 18px;
  padding-right: 18px;
  max-width: 100%;
  box-sizing: border-box;
`;

const gridContainer = css`
  display: flex;
  justify-content: space-between;
`;

const column = css`
  width: 324px;
`;

const titleContainer = css`
  @media (max-width: 720px) {
    width: 100%;
  }
`;

const title = css`
  font-family: "handwritten";
  font-size: 17pt;
  @media (max-width: 720px) {
    font-size: 14pt;
  }
`;

const date = css`
  font-family: "handwritten";
  margin-bottom: 30px;
  font-size: 15pt;
  @media (max-width: 720px) {
    font-size: 11pt;
  }
`;

const Column = ({ children, isMobile }) => {
  return (
    <div css={[column, { width: isMobile ? "100%" : 324 }]}>{children}</div>
  );
};

const NB_ITEMS_TO_LOAD = 12;

const Exhibition = ({ onImageClick }) => {
  let params = useParams();
  const [isBottom, setIsBottom] = useState(true);
  const [offset, setOffset] = useState(0);
  const [exhibition, setExhibition] = useState(null);
  const { width } = useViewport();
  const desktopBreakpoint = 1020;
  const mobileBreakpoint = 700;
  const [deviceType, setDeviceType] = useState(
    width <= mobileBreakpoint
      ? "mobile"
      : width <= desktopBreakpoint
      ? "tablet"
      : "desktop"
  );
  const [columns, setColumns] = useState(
    deviceType === "desktop"
      ? [
          { width: 324, height: 0, data: [] },
          { width: 324, height: 0, data: [] },
          { width: 324, height: 0, data: [] },
        ]
      : deviceType === "tablet"
      ? [
          { width: 324, height: 0, data: [] },
          { width: 324, height: 0, data: [] },
        ]
      : [{ width: 324, height: 0, data: [] }]
  );
  const [containerWidth, setContainerWidth] = useState(
    columns.length * 324 + (columns.length - 1) * GRID_SPACING
  );

  useEffect(() => {
    setDeviceType(
      width <= mobileBreakpoint
        ? "mobile"
        : width <= desktopBreakpoint
        ? "tablet"
        : "desktop"
    );
  }, [width]);

  useEffect(() => {
    setOffset(0);
    setColumns(
      deviceType === "desktop"
        ? [
            { width: 324, height: 0, data: [] },
            { width: 324, height: 0, data: [] },
            { width: 324, height: 0, data: [] },
          ]
        : deviceType === "tablet"
        ? [
            { width: 324, height: 0, data: [] },
            { width: 324, height: 0, data: [] },
          ]
        : [{ width: 324, height: 0, data: [] }]
    );
    setIsBottom(true);
  }, [deviceType]);

  useEffect(() => {
    setContainerWidth(
      columns.length === 1
        ? "100%"
        : columns.length * 324 + (columns.length - 1) * GRID_SPACING
    );
  }, [columns.length]);

  useEffect(() => {
    document.title = "Exhibitions | Zatchinabox";
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
      document.documentElement.scrollHeight - 400
    ) {
      setIsBottom(true);
    }
  };

  const fetchExhibitionImages = () => {
    axios
      .get(
        "/api/exhibition-pictures/" +
          params.exhibitionId +
          "/" +
          offset +
          "/" +
          NB_ITEMS_TO_LOAD
      )
      .then((results) => {
        let newColumns = columns;
        results.data.forEach((item) => {
          let ratio = 324 / item.imageWidth;
          let newHeight = item.imageHeight * ratio;
          let newWidth = 324;
          let indexOfMinHeightColumn = 0;
          let min = Number.POSITIVE_INFINITY;
          for (let i = 0; i < newColumns.length; i++) {
            if (min > newColumns[i].height) {
              min = newColumns[i].height;
              indexOfMinHeightColumn = i;
            }
          }
          newColumns[indexOfMinHeightColumn].data.push({
            imageSrc: item.imageSrc,
            imageHeight: newHeight,
            imageWidth: newWidth,
          });
          newColumns[indexOfMinHeightColumn].height += newHeight;
        });

        setColumns(newColumns);
        setOffset(offset + 1);
        setIsBottom(false);
      })
      .catch((err) => {});
  };

  const getExhibition = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/exhibitions/" + params.exhibitionId)
        .then((results) => {
          resolve(results.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  useEffect(() => {
    async function fetchExhibition() {
      const res = await getExhibition();
      setExhibition(res);
    }
    if (isBottom) {
      if (!exhibition) {
        fetchExhibition();
      } else {
        fetchExhibitionImages();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom, exhibition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div css={exhibitionStyle}>
      <Box height={180} />
      {exhibition && (
        <div css={[titleContainer, { width: containerWidth }]}>
          <div css={title}>{exhibition.title}</div>
          <div css={date}>
            {new Date(exhibition.date).toLocaleDateString("en-US")}
          </div>
        </div>
      )}
      <div css={[gridContainer, { width: containerWidth }]}>
        {columns.map((col, index) => {
          return (
            <Column key={index} isMobile={deviceType === "mobile"}>
              {col.data.map((item, index) => {
                return (
                  <ImageWithPlaceholder
                    style={{
                      height:
                        deviceType === "mobile" ? "auto" : item.imageHeight,
                      marginBottom: 14,
                    }}
                    imageUrl={item.imageSrc}
                    key={item.imageSrc}
                    onClick={onImageClick}
                  />
                );
              })}
            </Column>
          );
        })}
      </div>
    </div>
  );
};

export default Exhibition;
