/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useParams, useLocation, Redirect } from "react-router-dom";
import axios from "axios";
import background from "../../assets/square-placeholder.png";
import { Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useViewport } from "../../customHooks";
import { useHistory } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";

//const stripePromise = loadStripe("pk_test_LpwQNltPlJW8r5FlJda3lTxY");
const stripePromise = loadStripe("pk_live_Vt3clbUSYnKaz2BFE1g12b6m");

const container = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding-left: 18px;
  padding-right: 18px;
  max-width: 100%;
  box-sizing: border-box;
`;

const insideContainer = css`
  display: flex;
  @media (max-width: 830px) {
    flex-direction: column;
  }
`;

const rightContainer = css`
  max-width: 340px;
  p {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
  }
  @media (max-width: 830px) {
    max-width: none;
  }
`;

const imageStyleLoaded = css`
  opacity: 1;
  width: 100%;
  transition: opacity 0.5s;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
`;

const imageStyleNotLoaded = css`
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
`;

const imageWrapper = css`
  min-width: 500px;
  width: 500px;
  background-color: #2980b9;
  background: url(${background});
  background-position: center center;
  background-size: cover;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 830px) {
    min-width: 0;
    width: 100%;
  }
`;

const info = css`
  font-family: "handwritten";
  font-weight: bold;
  font-size: 23pt;
`;

const price = css`
  font-family: "arial";
  font-size: 16pt;
`;

const buyButton = css`
  width: 100%;
  @media (max-width: 830px) {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
`;

const productInfoTitle = css`
  font-family: "helvetica";
  color: #3d3d3d;
  font-weight: 600;
`;

const fetchProduct = (productId) =>
  new Promise((resolve, reject) => {
    axios
      .get("/api/artwork/" + productId)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const cmToInches = (cm) => {
  return (cm * 0.39370079).toFixed(1);
};

const toInchesStr = (str) => {
  let res = str.split(" ");
  res = res.map((item) => {
    if (!Number.isNaN(parseFloat(item))) {
      // a number
      return cmToInches(parseFloat(item)) + "''";
    } else if (item === "cm") {
      return "inches";
    } else {
      return item;
    }
  });
  return res.join(" ");
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Product = ({ onImageClick }) => {
  const params = useParams();
  const query = useQuery();
  const history = useHistory();
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const [product, setProduct] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { width } = useViewport();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    fetchProduct(params.productId)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => console.log(err));
    document.title = "Shop | Zatchinabox";
    window.scrollTo(0, 0);
  }, [params.productId]);

  const handleOnImageLoad = () => {
    setImageHasLoaded(true);
  };

  const handleOnImageClick = () => {
    if (onImageClick && product) {
      onImageClick({
        url: product.imageSrc,
        title: product.title,
      });
    }
  };

  const handleOnBuyButtonClick = () => {
    history.push({
      pathname: "/shop/" + params.productId,
      search: "?buy=true",
    });
  };

  return (
    <div css={container}>
      <Box height={180} />
      {product ? (
        query.get("buy") === "true" ? (
          product.quantity > 0 ? (
            <Elements stripe={stripePromise}>
              <StripeCheckoutForm product={product} />
            </Elements>
          ) : (
            <Redirect to={`/shop/${product.id}`} />
          )
        ) : (
          <div css={insideContainer}>
            <div
              css={[
                imageWrapper,
                {
                  height:
                    width >= 830
                      ? (500 / product.imageWidth) * product.imageHeight
                      : ((width - 36) / product.imageWidth) *
                        product.imageHeight,
                },
              ]}
              onClick={handleOnImageClick}
            >
              <img
                src={product.imageSrc}
                css={imageHasLoaded ? imageStyleLoaded : imageStyleNotLoaded}
                alt={product.imageSrc}
                onLoad={handleOnImageLoad}
              />
            </div>
            <Box css={{ minWidth: 40 }} />
            <div css={rightContainer}>
              <div css={info}>{product.title}</div>
              <Box height={40} />
              <div css={price}>{`$${numberWithCommas(product.price)}.00`}</div>
              <Box height={40} />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                css={buyButton}
                disabled={product.quantity === 0}
                onClick={handleOnBuyButtonClick}
              >
                {product.quantity === 0 ? "Sold" : "Buy"}
              </Button>
              <Box height={40} />
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div css={productInfoTitle}>Product info</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>-All prices are shown in USD.</p>
                    <p>-Original artwork.</p>
                    <p>-{capitalize(product.info)}.</p>
                    <p>
                      {`-${product.dimensions}  ~  ${toInchesStr(
                        product.dimensions
                      )}.`}
                    </p>
                    <p>-Includes a Certificate of Authenticity.</p>
                    <p>-Unframed.</p>
                    <p>-Signed and dated.</p>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <div css={productInfoTitle}>
                    Refunds and problems with your order
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      -Please note I deal with every return on a case by case
                      basis.
                    </p>
                    <p>-If you cannot find your order please contact me.</p>
                    <p>
                      -In the unlikely event that the artwork arrives damaged
                      please contact me with clear pictures of the damage.
                    </p>
                    <p>-Shipping fees are non refundable</p>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <div css={productInfoTitle}>Shipping</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      -Original artworks are carefully packed in rigid kraft
                      tubes or boxes.
                    </p>
                    <p>
                      -Sent via the postal service company USPS (when coming
                      from the US) and Colissimo (when coming from France).
                    </p>
                    <p>
                      -Once shipped, the tracking number will be provided to you
                      via email.
                    </p>
                    <p>
                      -Shipping cost is subject to change depending on the
                      location and the size of the artwork.
                    </p>
                    <p>
                      -Please allow 10 to 15 business days for international
                      deliveries.
                    </p>
                    <p>
                      -A confirmation email will be sent to you once your order
                      is on its way (within 3 business days after purchase, in
                      case of any delay you are to be notified as soon as
                      possible).
                    </p>
                    <p>
                      -Unfortunately I have no control over any import taxes
                      that might be charged upon delivery by customs, therefore
                      I do not offer any refund on these fees.
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <div css={productInfoTitle}>Copyrights</div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <p>
                      -Please note that all copyrights remain with the artist.
                    </p>
                    <p>
                      -The purchase of an original artwork does not allow the
                      buyer to use such piece for any commercial or promotional
                      purposes.
                    </p>
                    <p>
                      -The exploitation or misuse of the artwork might lead to
                      legal measures.
                    </p>
                    <p>
                      -To acquire licensing permits, please contact me via
                      email.
                    </p>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Product;
