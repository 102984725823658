/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";

const container = css`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
`;

const insideContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const text = css`
  font-size: 28pt;
  font-family: "handwritten";
  color: black;
  max-width: 630px;
  float: right;
  text-align: justify;
  @media (max-width: 720px) {
    padding-left: 18px;
    padding-right: 18px;
    font-size: 20pt;
  }
`;

const link = css`
  text-decoration: none;
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #d36b6b;
  }
  @media (max-width: 720px) {
    font-size: 20pt;
  }
`;

const About = () => {
  useEffect(() => {
    document.title = "About | Zatchinabox";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={container}>
      <div css={insideContainer}>
        <Box height={180} />
        <div css={text}>
          Hello world!
          <Box height={18} />
          I'm an artist based in New-York, I do all sorts of cool things, check
          it out{" "}
          <Link to="/work" css={link}>
            here
          </Link>
          !
          <Box height={18} />
          Feel free to{" "}
          <Link to="/contact" css={link}>
            contact me
          </Link>{" "}
          for any info or inquiries!
          <Box height={18} />
          And for all of you wanting to spend some crazy money and own some art,
          browse the{" "}
          <Link to="/shop" css={link}>
            shop
          </Link>{" "}
          ;) !
          <Box height={18} />
          Enjoy the tour!
        </div>
      </div>
    </div>
  );
};

export default About;
