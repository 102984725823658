/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder";

const news = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  padding-left: 18px;
  padding-right: 18px;
  max-width: 100%;
  box-sizing: border-box;
`;

const gridContainer = css`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  max-width: 100%;
  width: 900px;
  padding: 10px;
  &::before {
    content: "";
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  *:first-of-type {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`;

const gridItem = css`
  max-width: 100%;
  img {
    width: 100%;
  }
`;

const NB_ITEMS_TO_LOAD = 12;

const News = () => {
  const [posts, setPosts] = useState([]);
  const [isBottom, setIsBottom] = useState(true);
  const [offset, setIsOffset] = useState(0);

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (scrollTop + window.innerHeight + 50 >= scrollHeight) {
      setIsBottom(true);
    }
  };

  const fetchNews = () => {
    axios
      .get("/api/news/" + offset + "/" + NB_ITEMS_TO_LOAD)
      .then((results) => {
        setPosts([...posts, ...results.data]);
        setIsOffset(offset + 1);
        setIsBottom(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isBottom) {
      fetchNews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  useEffect(() => {
    document.title = "News | Zatchinabox";
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div css={news}>
      <Box height={180} />
      <div css={gridContainer}>
        {posts.map((item) => (
          <div css={gridItem} key={item.href}>
            <a target="blank" href={item.href}>
              <ImageWithPlaceholder imageUrl={item.src} overlay={true} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
