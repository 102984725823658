/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import Box from "@material-ui/core/Box";
import { useEffect } from "react";
import selfPortrait from "../../assets/selfPortrait.jpg";
import logo from "../../assets/logo.png";

const container = css`
  flex-grow: 1;
`;

const inside = css`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  padding-right: 18px;
  img {
    max-width: 100%;
  }
`;

const title = css`
  font-family: "handwritten";
  font-size: 27pt;
`;

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Zatchinabox";
  }, []);
  return (
    <div css={container}>
      <Box height={180} />
      <div css={inside}>
        <img src={selfPortrait} alt="self-portrait" />
        <Box height={12} />
        <div css={{ display: "flex", alignItems: "center" }}>
          <div css={title}>Zatchinabox</div>
          <Box width={15} />
          <img src={logo} width={100} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Home;
