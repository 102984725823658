/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import "./App.css";
import { jsx, css } from "@emotion/react";
import { useState } from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { ViewportProvider } from "./customHooks";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Lightbox } from "react-modal-image";
import routes from "./routes";

const body = css`
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -142px; /* the bottom margin is the negative value of the footer's height */
`;

const push = css`
  height: 200px;
`;

const drawerItem = css`
  color: #adadad;
  font-family: "handwritten";
  font-size: 20pt;
  text-decoration: none;
  padding-left: 25px;
  padding-right: 25px;
`;

const App = () => {
  const [zoomOpen, setZoomOpen] = useState(false);
  const [zoomUrl, setZoomUrl] = useState("");
  const [zoomTitle, setZoomTitle] = useState("");
  const [drawerOpenState, setDrawerOpenState] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpenState(open);
  };

  const handleCloseLightbox = () => {
    setZoomOpen(false);
    setZoomUrl("");
  };

  const handleOpenLightbox = (obj) => {
    setZoomUrl(obj.url);
    setZoomTitle(obj.title);
    setZoomOpen(true);
  };

  return (
    <ViewportProvider>
      <Router>
        <div css={body}>
          <NavBar toggleDrawer={toggleDrawer} />
          <div style={{ width: "100%", height: "100%" }}>
            <Switch>
              {routes.map((route, i) => (
                <Route
                  exact
                  path={route.path}
                  render={(props) => (
                    <route.component
                      {...props}
                      onImageClick={
                        route.onImageClick ? handleOpenLightbox : null
                      }
                    />
                  )}
                  key={i}
                />
              ))}
            </Switch>
          </div>
          <Drawer
            anchor={"left"}
            open={drawerOpenState}
            onClose={toggleDrawer(false)}
          >
            <div
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                {[
                  /*"Work",
                  "Exhibitions",
                  "Shop",
                  "About",
                  "Contact",*/
                  "Work",
                  "Exhibitions",
                  "Shop",
                  "NFT",
                  "About",
                  "Contact",
                ].map((text, index) => (
                  <ListItem button key={text}>
                    <NavLink
                      css={drawerItem}
                      to={"/" + text.toLowerCase()}
                      activeStyle={{
                        color: "black",
                        textDecoration: "underline",
                      }}
                    >
                      {text}
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </div>
          </Drawer>
          {zoomOpen && (
            <Lightbox
              large={zoomUrl}
              onClose={handleCloseLightbox}
              alt={zoomTitle}
            />
          )}
          <div css={push}></div>
        </div>
        <Footer />
      </Router>
    </ViewportProvider>
  );
};

export default App;
