/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import { Box } from "@material-ui/core";
import { useState } from "react";
import background from "../../assets/square-placeholder.png";
import { useViewport } from "../../customHooks";

const NY_TAX = 8;

const description = css`
  display: flex;
  @media (max-width: 830px) {
    flex-direction: column;
  }
`;

const imageStyleLoaded = css`
  opacity: 1;
  width: 100%;
  transition: opacity 0.5s;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
`;

const imageStyleNotLoaded = css`
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
`;

const imageWrapper = css`
  min-width: 300px;
  width: 300px;
  background-color: #2980b9;
  background: url(${background});
  background-position: center center;
  background-size: cover;
  @media (max-width: 830px) {
    min-width: 0;
    width: 100%;
  }
`;

const summary = css`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const summaryTitle = css`
  font-family: "handwritten";
  font-weight: bold;
  font-size: 20pt;
  margin-bottom: 20px;
`;

const summaryRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const line = css`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  border: none;
`;

const orderSummaryTitle = css`
  @media (max-width: 830px) {
    margin-top: 20px;
  }
`;

const promoCodeApplied = css`
  color: #33d64e;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getTax = (x) => {
  return x ? Math.floor((NY_TAX * x) / 100) : 0;
};

const OrderSummary = ({
  product,
  shippingRate,
  promoCodeSuccess,
  totalAmount,
}) => {
  const [imageHasLoaded, setImageHasLoaded] = useState(false);
  const { width } = useViewport();

  const taxPrice = getTax(product.price);

  const handleOnImageLoad = () => {
    setImageHasLoaded(true);
  };

  return (
    <div>
      {product && (
        <div css={description}>
          <div
            css={[
              imageWrapper,
              {
                height:
                  width >= 830
                    ? (300 / product.imageWidth) * product.imageHeight
                    : ((width - 36) / product.imageWidth) * product.imageHeight,
              },
            ]}
          >
            <img
              src={product.imageSrc}
              css={imageHasLoaded ? imageStyleLoaded : imageStyleNotLoaded}
              alt={product.imageSrc}
              onLoad={handleOnImageLoad}
            />
          </div>
          <Box width={25} />
          <div css={summary}>
            <div css={orderSummaryTitle}>Order Summary</div>
            <Box height={25} />
            <div css={summaryTitle}>{product.title}</div>
            <div css={summaryRow}>
              <div>Subtotal</div>
              <div>{`$${numberWithCommas(product.price)}.00`}</div>
            </div>
            <div
              css={[
                summaryRow,
                { textDecoration: promoCodeSuccess ? "line-through" : "none" },
              ]}
            >
              <div>Shipping</div>
              <div>
                {shippingRate ? `$${numberWithCommas(shippingRate)}.00` : "--"}
              </div>
            </div>
            {promoCodeSuccess && (
              <div css={promoCodeApplied}>
                <i>(Promo code applied)</i>
              </div>
            )}
            <div css={summaryRow}>
              <div>Taxes</div>
              <div>{`$${numberWithCommas(taxPrice)}.00`}</div>
            </div>
            <Box height={25} />
            <hr css={line} />
            <Box height={25} />
            <div css={summaryRow}>
              <div>Total</div>
              <div>{`$${numberWithCommas(totalAmount)}.00`}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderSummary;
