/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/react";
import Box from "@material-ui/core/Box";
import nftBanner from "../../assets/nft-feature.png";
import nftCalendarLogo from "../../assets/nft_calendar_logo_vector_optimized.svg";

const nfts = css`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
`;

const insideContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const text = css`
  font-size: 20pt;
  font-family: "handwritten";
  color: black;
  max-width: 630px;
  float: right;
  @media (max-width: 720px) {
    padding-left: 18px;
    padding-right: 18px;
    font-size: 16pt;
  }
`;

const a = css`
  &:visited {
    color: #d36b6b;
  }
`;

const NFTs = () => {
  return (
    <div css={nfts}>
      <Box height={180} />
      <div css={insideContainer}>
        <Box height={180} />
        <div css={text}>
          <div css={{ marginBottom: 15 }}>
            <a
              css={a}
              href="https://opensea.io/collection/crazyfamouspixels"
              target={"_blank"}
              rel="noreferrer"
            >
              Crazy Famous Pixels
            </a>
          </div>
          <div css={{ marginBottom: 35 }}>
            <a
              href="https://opensea.io/collection/crazyfamouspixels"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={nftBanner} alt={"nft banner"} width="100%" />
            </a>
          </div>
          <div css={{ marginBottom: 45 }}>
            As an artist and a programmer, I was instantly interested in the
            world of NFTs. This first collection of about 4700 NFTs is based on
            a painting project started in 2017. Massively influenced by Pop Art,
            I tried to use imagery from idol and mass culture. One of the aim
            was to use images of <i>'popular'</i> in a pixelated art form,
            emphasizing the banality of those elements and often getting a touch
            of irony and satire thanks to the massive amount of possible
            combinations. The backgrounds were all hand drawn in a cartoonish
            style, and the figures were generated through the use of pictures
            transformed by an algorithm of my own conception.
          </div>
          <div>
            <div css={{ fontFamily: "arial", marginBottom: 15 }}>
              As seen on:
            </div>
            <a
              href="https://nftcalendar.io/event/crazyfamouspixels-nt/"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={nftCalendarLogo}
                alt={"nft caldendar logo"}
                width="28%"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTs;
