/** @jsxImportSource @emotion/react */
/** @jsxRuntime classic /
/* @jsx jsx */
import { useState } from "react";
import { jsx, css } from "@emotion/react";
import background from "../assets/square-placeholder.png";

const placeholder = css`
  display: flex;
  position: relative;
  width: 100% !important;
  background-position: center center;
  background-size: cover;
  background-image: url(${background});
  &::before {
    content: "";
    display: block;
    //padding-top: 100%;
    z-index: -1;
  }
  /*img {
    opacity: 0;
    object-fit: cover;
    object-position: center;
    width: 100% !important;
    height: auto !important;
  }*/
  &:hover {
    cursor: pointer;
  }
`;

const ImageWithPlaceholder = ({ onClick, imageUrl, title = "", style }) => {
  const [imageStatus, setImageStatus] = useState("loading");

  const handleImageLoaded = () => {
    setImageStatus("loaded");
  };

  const handleImageErrored = () => {
    setImageStatus("failed to load");
  };

  const handleOnClick = () => {
    if (onClick)
      onClick({
        url: imageUrl,
        title: title,
      });
  };

  return (
    <div onClick={handleOnClick} css={[placeholder, style]}>
      <img
        css={{
          width: "100%",
          objectFit: "cover",
          height: "100%",
          opacity: imageStatus === "loaded" ? "1 !important" : 0,
          transition:
            imageStatus === "loaded" ? "opacity 0.25s ease-in-out" : "none",
          mozTransition:
            imageStatus === "loaded" ? "opacity 0.25s ease-in-out" : "none",
          WebkitTransition:
            imageStatus === "loaded" ? "opacity 0.25s ease-in-out" : "none",
        }}
        src={imageUrl}
        onLoad={handleImageLoaded}
        onError={handleImageErrored}
        alt="news"
      />
    </div>
  );
};
export default ImageWithPlaceholder;
